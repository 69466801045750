<template>
  <div v-if="isLoading">
    <OtherLoading />
  </div>
  <div v-else>
    <div class="title-tabs">General Infomation</div>
    <div class="bg-white p-3">
      <b-row>
        <b-col xs sm="12" md lg xl="6">
          <InputText
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="name"
            isRequired
            v-model="form.name"
            class="mb-2"
            :isValidate="v.form.name.$error"
            :v="v.form.name"
          />
        </b-col>
      </b-row>
    </div>
    <div class="title-tabs">
      <b-row>
        <b-col cols="6">Conditions</b-col>
        <b-col cols="6" class="text-right">
          <button
            @click.prevent="showCollapse"
            v-if="listFileImport.length == 0 || $route.params.id == 0"
            class="btn-edit w-100 text-right"
          >
            Edit
          </button>
          <button
            @click.prevent="showImport = true"
            v-if="
              listFileImport.length > 0 && $route.params.id != 0 && !showImport
            "
            class="btn-edit w-100 text-right"
            :disabled="fileLoading"
          >
            Import
          </button>
          <button
            @click.prevent="showImport = false"
            v-if="showImport"
            class="btn-edit w-100 text-right"
          >
            Cancel
          </button>
          <!-- <template>
            <b-button variant="custom-text" @click="exportTemplate"
              >Export Template</b-button
            >

            <b-button
              variant="custom-text"
              class="ml-2"
              @click="modalImport = true"
              >Import</b-button
            >
          </template> -->
        </b-col>
      </b-row>
    </div>
    <div class="bg-white black-color">
      <div class="p-3" v-if="isSelectedCondition">
        <DisplayResultSendMessageGroup
          :memberLevel="memberLevel"
          :stampList="stampCard"
          :gender="genderList"
          :branchList="branchList"
          :productList="productList"
          :campaignList="campaignList"
          :serviceList="serviceList"
          :privilegeList="privilegeCodeList"
          :settingValue="isSelectedCondition"
          :conditionList="conditionList"
          :isFileLoading="fileLoading"
          :form="form"
          :display="1"
          :customerTag="customerTag"
        />
        <!-- <div v-if="listFileImport.length == 0 && form.is_import == 1">
          <hr />
          Import User : {{ importExcelForm.file_name }}
        </div> -->
      </div>
      <b-col
        xs
        sm="12"
        md
        lg
        xl="6"
        v-if="id != 0 && listFileImport.length > 0 && showImport"
      >
        <div class="content-between">
          <label class="label-text"> Import User </label>
          <b-button variant="link" @click="exportTemplate()">
            Download Template
          </b-button>
        </div>
        <UploadFileV2
          textFloat=""
          placeholder="Please Choose File"
          format="excel"
          class="mb-0"
          name="file"
          :showWarningMessage="form.is_import"
          text="*Please upload only file .xlsx less than 10 MB"
          warningMessage="If you import a new file, the previously imported data will be
          overwritten by the new file."
          v-on:onFileChange="onFileChange"
          v-on:delete="deleteFile"
          :noDelete="$route.params.id != 0"
          :fileName="importExcelForm.file_name"
          v-model="importExcelForm.excel_file"
          :v="$v.importExcelForm.excel_file"
          id="uploadfile"
        />
      </b-col>
    </div>
    <div class="p-3">
      <b-button
        v-if="$route.params.id != 0 && form.is_import == 0"
        block
        variant="custom-primary"
        squared
        @click="updateSegment()"
        :disabled="form.is_process_done == 0"
      >
        {{
          form.is_process_done == 0
            ? "Updating Segment ..."
            : "Update Segment Customer"
        }}
      </b-button>
      <b-form-checkbox
        id="checkbox-1"
        class="mt-3"
        v-model="form.is_auto_update"
        name="checkbox-1"
        :value="1"
        :unchecked-value="0"
        :disabled="form.is_import == 1"
      >
        Enable Auto Update
      </b-form-checkbox>
    </div>

    <SidebarConditionGroup
      ref="sidebarCondition"
      :selected="isSelectedCondition"
      :customerTag="customerTag"
      :redeemItemList="redeemItemList"
      :conditionList="conditionList"
      :gender="genderList"
      :memberLevel="memberLevel"
      :stampList="stampCard"
      :branchList="branchList"
      :productList="productList"
      :campaignList="campaignList"
      :serviceList="serviceList"
      :privilegeList="privilegeCodeList"
      :formMain="form"
      :operatorList="operatorList"
      :listCondition="JSON.parse(JSON.stringify(form.condition_group))"
      @setForm="setForm"
      @importUser="onFileChange"
      :hasImportUser="true"
      :v="v.form.condition_group"
    >
    </SidebarConditionGroup>

    <b-modal
      v-model="modalImportFile"
      no-close-on-backdrop
      centered
      title="List Import File"
      hide-footer
    >
      <b-table striped hover :items="listFileImport" :fields="fields">
        <template v-slot:cell(created_time)="data">
          {{ $moment(data.item.created_time).format("DD MMM YYYY (HH:mm)") }}
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import SidebarConditionGroup from "@/components/automation/component/SidebarConditonGroup";
import DisplayResultSendMessageGroup from "@/components/automation/component/DisplayResultSendMessageGroup";
export default {
  components: {
    OtherLoading,
    SidebarConditionGroup,
    DisplayResultSendMessageGroup,
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    isSelectedCondition: {
      required: false,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: true,
      showCondition: false,
      operatorList: [
        {
          id: 1,
          name: ">",
        },
        {
          id: 2,
          name: "<",
        },
        {
          id: 3,
          name: "=",
        },
        {
          id: 4,
          name: ">=",
        },
        {
          id: 5,
          name: "<=",
        },
        {
          id: 6,
          name: "between",
        },
      ],
      stampCard: [],
      redeemItemList: [],
      memberLevel: [],
      genderList: [],
      campaignFieldConditions: [],
      privilegeCodeList: [],
      conditionList: [],
      branchList: [],
      productList: [],
      serviceList: [],
      campaignList: [],
      importExcelForm: {
        email: "",
        segment_id: this.$route.params.id,
        excel_file: "",
        file_name: "",
      },
      modalImport: false,
      modalImportFile: false,
      listFileImport: [],
      fields: [
        {
          key: "name",
          label: "File name",
        },
        {
          key: "created_time",
          label: "Import Time",
        },
      ],
      showImport: false,
      fileLoading: false,
      customerTag: [],
      isEditCondition: false,
    };
  },
  validations: {
    importExcelForm: {
      email: { required, email },
      excel_file: { required },
    },
  },
  async created() {
    this.getCustomerTag();
    this.getMemberLevel();
    this.getGender();
    this.getCampagin();
    this.getRedeemItem();
    this.getStampCard();
    this.getPrivilegeCode();
    this.getConditionList();
    this.getBranchList();
    this.getProductList();
    this.getServiceList();
    this.FileImport();
    // console.log(object);
    this.isLoading = false;
  },
  methods: {
    showCollapse() {
      this.$refs.sidebarCondition.show(true);
    },
    async getConditionList() {
      await this.$store.dispatch("getCondition");
      let data = this.$store.state.automation.conditionList;

      if (data.result == 1) {
        this.conditionList = data.detail.filter(
          (el) =>
            el.id != 12 &&
            el.id != 13 &&
            el.id != 14 &&
            el.id != 20 &&
            el.id != 30 &&
            el.id != 31
        );
      }
    },
    async getGender() {
      await this.$store.dispatch("getGender");
      let data = this.$store.state.automation.genderList;
      if (data.result == 1) {
        for (const gd of data.detail) {
          if (gd.id !== "0") {
            this.genderList.push({
              id: gd.id,
              name: gd.name,
              year: gd.year,
            });
          }
        }
      }
    },
    async getStampCard() {
      await this.$store.dispatch("getStampCardInAutomation");
      const data = this.$store.state.automation.stampCardInAutomation;

      if (data.result == 1) {
        this.stampCard = data.detail;
      }
    },
    async getRedeemItem() {
      const res = await this.axios(`/MarketingAutomation/getredeemitem`);
      if (res.data.result == 1) {
        this.redeemItemList = res.data.detail;
      }
    },
    async getMemberLevel() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevel = data.detail;
      }
    },

    async getCampagin() {
      await this.$store.dispatch("getCampaign");
      let data = this.$store.state.automation.campaignList;
      if (data.result == 1) {
        this.campaignList = data.detail;
      }
    },
    async getPrivilegeCode() {
      await this.$store.dispatch("getPrivilegeCodeInAutomation");
      const data = this.$store.state.automation.privilegeCodeInAutomation;
      if (data.result == 1) {
        this.privilegeCodeList = data.detail;
      }
    },
    async getBranchList() {
      let data = await this.axios(`/MarketingAutomation/GetBranchList`);

      this.branchList = data.data.detail;
    },
    async getProductList() {
      let data = await this.$store.dispatch(`getProductList`, {
        take: 999999,
        page: 1,
        search: "",
      });
      // data.data.detail.length = 3000;
      setTimeout(() => {
        console.log(data);
        this.productList = data.detail.product_list;
      }, 1000);
    },
    async getServiceList() {
      let data = await this.axios(`/MarketingAutomation/GetServiceList`);
      this.serviceList = data.data.detail;
    },
    async sideBarCondition(type) {
      this.$refs.sidebarCondition.show(type);
    },
    setForm(val) {
      if (val.length > 0) {
        this.form.condition_group = val;
        this.$emit("settingCondition", 1, val);
      } else {
        this.$emit("settingCondition", 2, val);
      }
    },
    async updateSegment() {
      try {
        this.$bus.$emit("showLoading");
        let data = await this.axios(
          `/customer/segment_auto_process/${this.$route.params.id}`
        );
        this.$bus.$emit("hideLoading");
        if (data.data.result == 1) {
          this.successAlert().then(() => this.$router.go());
        } else {
          this.errorAlert(data.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async FileImport() {
      try {
        let data = await this.axios(
          `/customer/segment/list_file_import/${this.$route.params.id}`
        );
        if (data.data.result == 1) {
          if (data.data.detail.length > 0) {
            if (data.data.detail[0].name == "") this.fileLoading = true;
            this.importExcelForm.file_name = data.data.detail[0].name;
            this.listFileImport = data.data.detail;
          }
        }
      } catch (error) {
        console.log(error);
      }
      // this.serviceList = data.data.detail;
    },
    async exportTemplate() {
      let data = await this.axios(`/customer/segment/export_template`, {
        responseType: "blob",
      });

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `segment-template.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    onFileChange(file) {
      this.importExcelForm.file_name = file.name;

      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.is_import = 1;
        this.form.is_auto_update = 0;
        this.importExcelForm.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.importExcelForm.excel_file = null;
      this.importExcelForm.file_name = null;
      this.form.is_import = 0;
    },
    async importFiles() {
      this.$v.$touch();
      if (this.$v.$error) return;
      if (this.form.is_import == 1)
        this.confirmAlert({
          message:
            "If you import a new file, the previously imported data will be overwritten by the new file.",
          title: "Do you want to import the file now?",
          icon: "warning",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
        }).then(async (val) => {
          if (val.isConfirmed) {
            this.$bus.$emit("showLoading");
            const result = await this.axios.post(
              "/customer/segment/import_customer",
              this.importExcelForm
            );
            this.$bus.$emit("hideLoading");
            if (result.data.result) {
              this.modalImport = false;
              this.successAlert();
            } else this.errorAlert(result.data.message);
          }
        });
      else {
        this.$bus.$emit("showLoading");
        const result = await this.axios.post(
          "/customer/segment/import_customer",
          this.importExcelForm
        );
        this.$bus.$emit("hideLoading");
        if (result.data.result) {
          this.modalImport = false;
          this.successAlert();
        } else this.errorAlert(result.data.message);
      }
    },
    modalHidden() {
      this.$v.$reset();
      this.importExcelForm = {
        email: "",
        segment_id: this.$route.params.id,
        excel_file: "",
        file_name: "",
      };
    },
    async getCustomerTag() {
      // await this.$store.dispatch("getRedeemCode", this.id);
      const data = await this.axios("/MarketingAutomation/getcustomertag");

      // if (data.data.result == 1) {
      this.customerTag = data.data.detail;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.black-color {
  color: #000;
}
.btn-edit {
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0 !important;
}
</style>
